<template>
  <div :class="`left-menu ${isCollapse?'left-menu--collapse' :''}`">
    <div class="logo" @click="$router.push({name:'USER_INFO'})">
        <img :src="isCollapse?require('@/assets/logo_short.png'):require('@/assets/logo.png')" />
    </div>
    <el-menu
      ref="leftMenu"
      :collapse-transition="false"
      :collapse="isCollapse"
      @select="handleSelectMenu"
      :default-active='$store.state.common.defaultActiveName'>
      <template v-for="item in menuLists">
        <el-submenu :index="item.name" v-if="item.children && item.children[0]"  :key="item.name+item.meta.title" class="test">
            <template slot="title">
              <i :class="item.icon + ' iconfont'"></i>
               <span slot="title" v-if="!isCollapse"> {{item.meta.title}}</span>
            </template>
            <template v-for="i in item.children" >
                <el-submenu :index="i.name" v-if="i.children && i.children[0]" :key="i.name+i.meta.title"  class="test">
                    <template slot="title">{{i.meta.title}} </template>
                    <el-menu-item :index="j.url || j.name" v-for="j in i.children" :key="j.name+j.meta.title">{{j.meta.title}}</el-menu-item>
                </el-submenu>
                <el-menu-item v-else :index="i.url || i.name" :key="i.name+i.meta.title">{{i.meta.title}}</el-menu-item>
            </template>
        </el-submenu>
        <el-menu-item v-else :index="item.url || item.name"  :key="item.name+item.meta.title">
          <span slot="title">{{item.meta.title}}</span>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'leftMenu',
  data () {
    return {
    };
  },
  props: {
    isCollapse: Boolean,
  },
  methods: {
    // 选中的导航
    handleSelectMenu (nav) {
      this.$store.dispatch('changeDefaultActive', nav);
      this.$router.push({
        name: nav,
      }).catch(err => { err; });
    },
    handleShortcutClick (item) {
      this.$store.dispatch('changeDefaultActive', item.name);
      this.$router.push(item.url);
    },
  },
  watch: {
    $route: {
      handler: function (route) {
        let name = route.name;
        let currentLists = [];
        let menuLists = this.menuLists;
        for (var i = 0; i < menuLists.length; i++) {
          let menu = menuLists[i];
          for (var j = 0; j < menu.children.length; j++) {
            var subMenu = menu.children[j];
            if (subMenu.name === name) {
              currentLists = [menu, subMenu];
              this.$store.dispatch('changeBreadLists', currentLists);
              break;
            }
          }
        }
        if (currentLists.length < 1) {
          if (!['PersonalCenter', 'Company'].includes(name)) {
            currentLists = this.$store.state.common.breadLists;
          } else {
            currentLists = [];
          }
          currentLists.push({
            path: route.path,
            name: route.name,
            meta: route.meta,
          });
          if (currentLists.length > 3) {
            currentLists.length = 3;
          }
          this.$store.dispatch('changeBreadLists', currentLists);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      'menuTree',
      'menuIdMap',
      'menuUrlMap',
    ]),
    defaultActive () {
      let matchedMenuList = this.menuUrlMap[this.$route.path] || [];
      matchedMenuList = matchedMenuList.sort((a, b) => a.type >= b.type);
      let breadcrumbList = [];
      let currentMenu = matchedMenuList[0];
      if (currentMenu && this.leftMenuTreeMap) {
        breadcrumbList.unshift(currentMenu);
        let getParent = menuItem => {
          let parent = this.menuIdMap[menuItem.parentId];
          if (parent) {
            breadcrumbList.unshift(parent);
            if (parent.parentId) {
              getParent(parent);
            }
          }
        };
        getParent(currentMenu);
        for (let index = breadcrumbList.length - 1; index >= 0; index--) {
          const menu = breadcrumbList[index];
          if (this.leftMenuTreeMap.get(menu.url)) {
            return menu.url;
          }
        }
      }
      return this.$route.path;
    },
    leftMenuTreeMap () {
      // 缓存当前左侧菜单map
      let leftMenuTreeMap = new Map();
      let cacheMenuTree = (menuList) => {
        menuList.forEach(t => {
          if (t.children) {
            cacheMenuTree(t.children);
          } else if (t.url) {
            leftMenuTreeMap.set(t.url, true);
          }
        });
      };
      cacheMenuTree(this.leftMenuList);
      return leftMenuTreeMap;
    },
    leftMenuList () {
      // let withoutAuth = 'auth' in meta && !meta.auth;
      let currentModule = this.menuUrlMap[this.$route.path];
      if (currentModule && currentModule[0]) {
        return this.menuTree.find(t => t.sysFlag.toUpperCase() === currentModule[0].sysFlag.toUpperCase()).children;
      } else {
        return this.menuTree.find(t => t.sysFlag.toUpperCase() === 'SAAS')?.children;
      }
    },
    menuLists () {
      let currentLists = [
        {
          path: '/home',
          name: 'HOME',
          icon: 'icon-yonghuzhongxin',
          component: () => import('@/components/common/layout/index.vue'),
          redirect: {name: 'USER_INFO'},
          meta: {
            title: '用户中心',
          },
          children: [
            {
              path: '/userInfo',
              name: 'USER_INFO',
              component: () => import('@/views/userCenter/index.vue'),
              meta: {
                title: '用户首页',
              },
            },
          ],
        },
        {
          path: '',
          name: 'SalesManagement',
          icon: 'icon-xiaoshouguanli',
          meta: {
            title: '销售管理',
          },
          redirect: {name: 'Product'},
          children: [
            {
              path: '/product',
              name: 'Product',
              component: () => import('@/views/product/index.vue'),
              meta: {
                title: '商品管理',
              },
            },
            {
              path: '/saleContract',
              name: 'SaleContract',
              component: () => import('@/views/saleContract/index.vue'),
              meta: {
                title: '销售合同',
              },
            },
          ],
        },
        {
          path: '',
          name: 'DeliveryManagement',
          icon: 'icon-fahuoguanli',
          meta: {
            title: '发货管理',
          },
          children: [
            {
              path: '/pickUpOrders',
              name: 'PickUpOrders',
              component: () => import('@/views/pickUpOrders/index.vue'),
              meta: {
                title: '提货订单',
              },
            },
            {
              path: '/invoiceMgr',
              name: 'InvoiceMgr',
              component: () => import('@/views/invoiceMgr/index.vue'),
              meta: {
                title: '发货单管理',
              },
            },
          ],
        },
        {
          path: '',
          name: 'AfterSalesManagement',
          icon: 'icon-shouhouguanli',
          meta: {
            title: '售后管理',
          },
          children: [
            {
              path: '/service',
              name: 'Service',
              component: () => import('@/views/service/afterSales/index.vue'),
              meta: {
                title: '销售售后',
              },
            },
            {
              path: '/service/return',
              name: 'ServiceReturn',
              component: () => import('@/views/service/receiveReturns/index.vue'),
              meta: {
                title: '售后退货收货',
              },
            },
          ],
        },
        {
          path: '',
          name: 'SalesReconciliation01',
          icon: 'icon-xiaoshouduizhang',
          meta: {
            title: '销售对账',
          },
          // redirect: {name: 'AccountStatement'},
          children: [
            {
              path: '/service/accountStatement/list',
              name: 'AccountStatement',
              component: () => import('@/views/salesReconciliation/accountStatement/index.vue'),
              meta: {
                title: '对账单',
              },
            },
            {
              path: '/deliveryRecord',
              name: 'DeliveryRecord',
              component: () => import('@/views/salesReconciliation/deliveryRecord.vue'),
              meta: {
                title: '出库记录',
              },
            },
            {
              path: '/returnRecord',
              name: 'ReturnRecord',
              component: () => import('@/views/salesReconciliation/returnRecord.vue'),
              meta: {
                title: '退货记录',
              },
            },
          ],
        },
        {
          path: '',
          name: 'companyManagement',
          icon: 'icon-qiyeguanli',
          meta: {
            title: '企业管理',
          },
          children: [
            {
              path: '/staff',
              name: 'Staff',
              component: () => import('@/views/staff/index.vue'),
              meta: {
                title: '员工授权',
              },
            },
          ],
        },
      ];
      if (this.$store.state.User.userInfo.identity !== 'ADMIN') {
        currentLists.splice(5, 1);
      }
      return currentLists;
    },
  },
};
</script>

<style lang='scss'>
  .left-menu,.el-menu--popup{
    .el-submenu .el-submenu__title,.el-menu-item {
      height: 46px!important;
      line-height: 46px!important;
    }
    .el-submenu__title,.el-menu-item {
      color:hsla(0,0%,100%,.65);

      &:focus {
        background-color: #1890ff;
        color: white;
        i{
          color: white;
        }
      }

      &:hover{
        background-color: transparent;
        color: white;
        i{
          color: white;
        }
      }
    }

    .el-submenu.is-active .el-submenu__title {
      background-color: transparent;
      color: white;
      i{
        color: white;
      }
    }

    .el-menu-item.is-active{
      background-color: #1890ff;
      color: white;
      i{
        color: white;
      }
    }
  }

  .el-menu--vertical .el-menu{
    background-color: #001529;
  }

  .left-menu {
    background-color: #001529;
    height: 100%;
    width: 220px;
    display: flex;
    flex-direction: column;
    .el-menu {
      flex: 1;
      overflow: auto;
      background-color: transparent;
      border-color: transparent;
      color: white;

      i[class~=iconfont] {
        margin-right: 5px;
        vertical-align: middle;
        width: 24px;
        text-align: center;
        display: inline-block;
      }
    }

    .logo {
      line-height: 50px;
      height: 50px;
      font-size: 0;
      vertical-align: middle;
      padding-left: 20px;
      cursor: pointer;
      img {
        height: 40px;
        vertical-align: middle;
      }
    }

    .shortcut_menu {
      line-height: 50px;
      height: 50px;
      padding-left:20px ;
      font-size: 14px;
      vertical-align: middle;
      border-bottom: 1px solid rgba(255,255,255,0.2);
      color:hsla(0,0%,100%,.65);
      &:hover {
        color: white;
      }

      i[class^=el-icon] {
        margin-right: 5px;
        vertical-align: middle;
        width: 24px;
        text-align: center;
        font-size: 18px;
      }
    }

    .menu_action {
      color: white;
      font-size: 18px;
      height: 50px;
      line-height: 50px;
      vertical-align: middle;
      padding-left: 20px;
    }

    &.left-menu--collapse {
      width: auto;
      .logo {
        text-align: center;
        padding-left: 0px;
      }

      .shortcut_menu {

        i[class^=el-icon] {
          margin: 0;
        }
        span {
          height: 0;
          width: 0;
          overflow: hidden;
          visibility: hidden;
          display: inline-block;
        }
      }
    }
  }

  .shortcut-list-popover{
    width: 154px;
    background-color: #001529;
    color:hsla(0,0%,100%,.65);

    ul.shortcut-list {
      max-height: 352px;
      width: 100%;
      font-size: 14px;
      overflow: auto;

      li {
        line-height: 38px;
        height: 38px;
        vertical-align: middle;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          // background-color: #ecf5ff;
          color: white;
        }
      }
    }

    &.el-popper .popper__arrow::after{
      border-right-color: #001529;
    }
  }

</style>
