<template>
<div class="layout">
    <LeftMenu :isCollapse="isCollapse"></LeftMenu>
    <el-container v-if="isLoaded" class="container">
        <Header @collapse="val=>isCollapse=val"></Header>
        <el-main>
            <breadcrumb v-if="!isDashboardPage"/>
            <div :class="`${isDashboardPage? 'container_dashboard': 'container__content'}`">
                <!-- <transition name="moveR"> -->
                    <router-view></router-view>
                <!-- </transition> -->
            </div>
        </el-main>
    </el-container>
</div>
</template>
<script>
import LeftMenu from './leftMenu.vue';
import Header from './header.vue';
import Breadcrumb from './breadcrumb';
import { mapGetters } from 'vuex';

export default {
  name: 'layout',
  components: {
    LeftMenu,
    Header,
    Breadcrumb,
  },
  data () {
    return {
      isCollapse: false,
    };
  },
  computed: {
    ...mapGetters([
      'menuTree',
    ]),
    isLoaded () {
      return true;
    },
    isDashboardPage () {
      return this.$route.path === '/home/dashboard';
    },
  },
};
</script>

<style lang='scss' scoped>
    .layout {
        height: 100%;
        display: flex;

        >.container{
            flex: 1;
            flex-direction: column;
            overflow: hidden;

            /deep/>.el-main {
              padding: 0 12px;
              background-color: rgb(240,242,245);
              display: flex;
              flex-direction: column;

              .container__content{
                // overflow: auto;
                // overflow-y: hidden;
                flex: 1;
                height: 0;
              }

              .container_dashboard{
                margin: 0 -12px;
              }
            }
        }
    }
    .moveR-enter-active,  .moveR-leave-active {
        transition: all 0.3s linear;
        transform: translateX(0);
    }
    .moveR-enter,  .moveR-leave {
        transform: translateX(100%);
    }
   .moveR-leave-to{
     transform: translateX(100%);
   }
</style>
