<template>
    <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb-container">
        <!-- 这里需要确定具体展示内容 -->
        <el-breadcrumb-item v-for="nav in $store.state.common.breadLists" :to="{ path: nav.path }" :key='nav.name' >{{nav.meta.title}}</el-breadcrumb-item>
    </el-breadcrumb>
</template>

<script>
export default {
  name: 'breadcrumb',
};
</script>

<style lang='scss' scoped>
    .breadcrumb-container{
      line-height: 38px;
      height: 38px;
      vertical-align: middle;
    }
</style>
